export default {

  data() {
    return {

      column: '',
      items: [],
      sort: 'asc',
    };
  },
  methods: {

    sortColumn(column, type = 'number', dataKey = 'items', secondKey) {
      this.sort = (this.sort === 'desc') ? 'asc' : 'desc';
      this.column = column;

      if (secondKey) {
        if (this.sort === 'desc') {
          this[dataKey].sort((first, second) => type === 'number' ? parseFloat(second[this.column][secondKey]) - parseFloat(first[this.column][secondKey]) : (second[this.column][secondKey] < first[this.column][secondKey]) ? -1 : 1);
        }
        else { this[dataKey].sort((first, second) => type === 'number' ? parseFloat(first[this.column][secondKey]) - parseFloat(second[this.column][secondKey]) : (first[this.column][secondKey] < second[this.column][secondKey]) ? -1 : 1); }
      }
      else {
        if (this.sort === 'desc') {
          this[dataKey].sort((first, second) => type === 'number' ? parseFloat(second[this.column] || 0) - parseFloat(first[this.column] || 0) : (second[this.column] < first[this.column]) ? -1 : 1);
        }
        else { this[dataKey].sort((first, second) => type === 'number' ? parseFloat(first[this.column] || 0) - parseFloat(second[this.column] || 0) : (first[this.column] < second[this.column]) ? -1 : 1); }
      }
    },
    sortRemote(column) {
      this.sort = (this.sort === 'desc' && column === this.column) ? 'asc' : 'desc';
      this.column = column;

      this.refresh();
    },
  },
};
