<template>

  <div>
    <div v-if="Object.values(options).some(item => filters[item.model].length)" class="filtered-items small mr-2 d-flex align-items-center">
      <div
        v-for="(item, keyName) in options"
        :key="keyName"
        class="d-flex">
        <span
          v-for="(model, index) in filters[item.model]"
          :key="index"
          class="selected-badge ml-2 d-flex align-items-center">{{model}} <i class="fe fe-x ml-1 cursor-pointer" @click.prevent="removeFilter(item.model, index)"></i></span>
      </div>
      <button class="btn btn-link" @click.prevent="resetFilters">Reset</button>
    </div>
    <div class="dropdown custom-menu">
      <button
        class="btn btn-secondary dropdown-toggle"
        data-toggle="dropdown"
        @click.prevent>
        <i class="fe fe-filter"></i> Filter by <i class="fe fe-chevron-down"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-right">
        <div class="dropdown-item">
          <div
            v-for="(item, keyName) in options"
            :key="keyName"
            class="form-group">
            <label class="form-label text-capitalize">{{item.label}}</label>
            <autocomplete
              v-model="filters[item.model]"
              :list="availableList(keyName)"
              :multiple="true"
              :local="true"
              label="name"
              :entity="item.label"
              :placeholder="`Select ${item.label}`"
            />
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-sm btn-link" @click.prevent="resetFilters">Reset</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Autocomplete from '@/components/Autocomplete';

export default {

  components: {

    Autocomplete,
  },
  props: {

    filters: {

      required: true,
      type: Object,
    },
    items: {

      default: () => [],
      required: true,
      type: Array,
    },
    options: {

      required: true,
      type: Object,
    },
  },
  computed: {

    availableList() {
      return type => {
        return {items: [...new Set(this.items.map(item => item[type]))]};
      };
    },
  },
  methods: {

    removeFilter(key, index) {
      this.filters[key].splice(index, 1);
    },
    resetFilters() {
      const models = {};

      Object.values(this.options).forEach(item => {
        models[item.model] = [];
      });

      Object.assign(this.filters, models);
    },
  },
};

</script>

<style scoped>

    .custom-menu button.dropdown-toggle,
    .custom-menu button.dropdown-toggle:focus,
    .custom-menu button.dropdown-toggle:active,
    .custom-menu button.dropdown-toggle:visited {
        outline: none;
    }
    .custom-menu button.dropdown-toggle:hover,
    .custom-menu button.dropdown-toggle:focus,
    .custom-menu button.dropdown-toggle:visited {

        background: inherit;
    }
    .custom-menu .dropdown-item {
        min-width: 30rem;
    }
    .custom-menu .dropdown-item:hover,
    .custom-menu .dropdown-item:focus,
    .custom-menu .dropdown-item:active {
        background: initial;
        color: initial;
    }

    .selected-badge {
        border: 0;
        cursor: pointer;
        background: #e3f2fd;
        color: #039be5;
        font-size: 12px;
        border-radius: 2px;
        padding: .25rem;
        margin: .15rem;
        white-space: normal;
    }

</style>
