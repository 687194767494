<template>

  <div>
    <template v-if="edit">
      <form :class="{'was-validated': $v.filter.$dirty}" @submit.prevent="validate()">
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label form-label text-right">Weekly Menu</label>
          <div class="col-sm-7">
            <select
              v-model="$v.filter.weeklyMenu.$model"
              class="form-control custom-select"
              :class="{'is-invalid': $v.filter.weeklyMenu.$error}">
              <option :value="null">Select weekly menu</option>
              <option
                v-for="week in weeklyMenus"
                :key="week.id"
                :value="week">
                {{weekName(week.startDate, {weekFrom: false})}}
              </option>
            </select>
            <template v-if="$v.filter.weeklyMenu.$dirty">
              <div v-if="!$v.filter.weeklyMenu.required" class="invalid-feedback d-block">Weekly menu is required</div>
            </template>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-3 form-label text-right">Customer delivery days</label>
          <div class="col-sm-7">
            <label
              v-for="date in deliveryDays"
              :key="date.id"
              class="form-check form-check-inline">
              <input
                class="form-check-input"
                :checked="!!$v.filter.days.$model.find(item => item.id === date.id)"
                type="checkbox"
                @change="onDateChange(date)">
              <span class="form-check-label">{{date.name}}</span>
            </label>
            <label
              class="form-check form-check-inline">
              <input
                v-model="selectAllDays"
                class="form-check-input"
                type="checkbox"
                @change="handleSelectAllDays">
              <span class="form-check-label">Select All</span>
            </label>
            <template v-if="$v.filter.days.$dirty">
              <div v-if="!$v.filter.days.required" class="invalid-feedback d-block">Days is required</div>
            </template>
          </div>
        </div>
        <template v-if="!viewOnly">
          <div class="form-group row d-flex">
            <label class="col-sm-3 col-form-label form-label text-right">Category</label>
            <div class="col-sm-7">
              <multi-tree-select
                v-model="$v.filter.category.$model"
                :list.sync="categories"
                :class="{'is-invalid': $v.filter.category.$error}"
                :nextPage="nextCategories"
                :multiple="false"
                :normalizer="categoryNormalizer"
                label="name"
                placeholder="Select category"
                entity="category"
                plural="categories"
              />
              <template v-if="$v.filter.category.$dirty">
                <div v-if="!$v.filter.category.required" class="invalid-feedback d-block">Category is required</div>
              </template>
            </div>
          </div>
          <div class="form-group row d-flex">
            <label class="col-sm-3 col-form-label form-label text-right">Suppliers <small class="text-muted">(Optional)</small></label>
            <div class="col-sm-7">
              <autocomplete
                v-model="filter.suppliers"
                :list.sync="suppliers"
                :nextPage="nextSuppliers"
                :multiple="true"
                label="name"
                entity="supplier"
                placeholder="Select suppliers"
              />
            </div>
          </div>
        </template>
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label form-label text-right">Purchase type</label>
          <div class="col-sm-7">
            <select
              v-model="$v.filter.purchaseType.$model"
              class="form-control custom-select"
              :class="{'is-invalid': $v.filter.purchaseType.$error}"
            >
              <option value="">Select purchase type</option>
              <option v-if="can(uiPermissions.PURCHASE_ORDERS_ACTUAL_VIEW)" value="actual">Actual</option>
              <option v-if="can(uiPermissions.PURCHASE_ORDERS_FORECAST_VIEW)" value="forecast">Forecast</option>
            </select>
            <template v-if="$v.filter.purchaseType.$dirty">
              <div v-if="!$v.filter.purchaseType.required" class="invalid-feedback d-block">Purchase type is required</div>
            </template>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-auto">
            <button class="form-control btn btn-primary">Next</button>
          </div>
          <template v-if="isForecast && can(uiPermissions.PURCHASE_ORDERS_FORECAST_EXPORT)">
            <div class="col-auto">
              <button class="form-control btn btn-primary" @click.prevent="exportArticleForecast('purchase-me')">
                Export purchase me
              </button>
            </div>
            <div class="col-auto">
              <button class="form-control btn btn-primary" @click.prevent="exportArticleForecast('purchasable-and-packable')">
                Export purchasable and packable
              </button>
            </div>
            <div class="mt-4 text-muted small">Only weekly menu, category, and customer delivery day filters apply for forecast exports</div>
          </template>
        </div>
      </form>
    </template>
    <template v-else>
      <form-description label="Weekly menu" :value="weekName(filter.weeklyMenu.startDate, {weekFrom: false})"/>
      <form-description label="Customer delivery days" :value="filter.days.map(day => day.name).join(', ')"/>
      <template v-if="!viewOnly">
        <form-description label="Category" :value="filter.category.name"/>
        <form-description label="Suppliers" :value="filter.suppliers.map(item => item.name).join(', ')"/>
      </template>
      <form-description label="Purchase type" :value="filter.purchaseType"/>
    </template>
  </div>

</template>

<script>
import Autocomplete from '@/components/Autocomplete';
import FormDescription from '@/components/FormDescription';
import MultiTreeSelect from '@/components/MultiTreeSelect';
import {categories, deliveryDays, suppliers, weeklyMenusBasic} from '@/services';
import {thursdayBasedWeek, toggleArray} from '@hellochef/shared-js/helpers';
import moment from 'moment-timezone';
import {required, requiredIf} from 'vuelidate/lib/validators';

export default {

  components: {
    Autocomplete,
    FormDescription,
    MultiTreeSelect,
  },
  props: {
    edit: {
      default: true,
      type: Boolean,
    },
    filter: {
      required: true,
      type: Object,
    },
    viewOnly: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      categories: [],
      categoryNormalizer(node) {
        return {
          children: node ? node.sub_categories : [],
          label: node ? node.name : '',
        };
      },
      deliveryDays: [],
      suppliers: [],
      weeklyMenus: [],
      selectAllDays: false,
    };
  },
  computed: {
    isForecast() {
      return this.filter.purchaseType === 'forecast';
    },
  },
  created() {
    deliveryDays.getByParameters({column: 'id', direction: 'asc'}).then(result => this.deliveryDays = result.items);
    categories.getByParameters({column: 'id', direction: 'asc'}).then(result => this.categories = result);
    suppliers.getByParameters({column: 'id', direction: 'asc'}).then(result => this.suppliers = result);
    weeklyMenusBasic.getByParameters({
      column: 'id',
      direction: 'desc',
      limit: 25,
      startDateFrom: thursdayBasedWeek(moment()).format('YYYY-MM-DD'),
      status: ['published', 'scheduled'],
    })
      .then(result => {
        this.weeklyMenus = result.items;
        const startWeek = this.getPurchaseStartWeek();

        // set default earliest week available
        this.$v.filter.weeklyMenu.$model = this.weeklyMenus[this.weeklyMenus.length - startWeek];
      });
  },
  methods: {
    async exportArticleForecast(type) {
      this.$emit('export', type);
    },
    async nextCategories(page, query) {
      const params = {
        column: 'name',
        direction: 'asc',
        page,
      };
      if (query.length) params.query = query;
      const result = await categories.getByParameters(params);
      return result;
    },
    async nextSuppliers(page, query) {
      const params = {
        column: 'name',
        direction: 'asc',
        page,
      };

      if (query.length) params.query = query;
      const result = await suppliers.getByParameters(params);
      return result;
    },
    validate(eventName = 'valid') {
      this.$v.filter.$touch();

      if (!this.$v.filter.$invalid) {
        this.$emit(eventName, this.filter);
      }
    },
    /**
     *
     * Sets default week for purchasing articles
     *
     * @return integer
     */
    getPurchaseStartWeek() {
      // by default, they need the current week for thursday = 4 and friday = 5 only
      const daysForCurrentWeek = ['4', '5'];

      // if it's not thursday or friday we should grab next week as default
      return daysForCurrentWeek.includes(moment().format('d')) ? 1 : 2;
    },
    onDateChange(date) {
      // if the select all is checked, uncheck it
      this.selectAllDays = false;
      toggleArray(this.$v.filter.days.$model, date, (array, selected) => array.id === selected.id);
    },
    handleSelectAllDays(event) {
      // clear the days array
      this.$v.filter.days.$model = [];

      // if the select all is checked, add all days to the days array
      if (event.target.checked) {
        this.deliveryDays.forEach(day => {
          toggleArray(this.$v.filter.days.$model, day, (array, selected) => array.id === selected.id);
        });
      }
    },
  },
  validations: {
    filter: {
      category: {
        required: requiredIf(function(value) {
          return !this.viewOnly;
        }),
      },
      days: {
        required: value => value.length,
      },
      purchaseType: {
        required,
      },
      weeklyMenu: {
        required,
      },
    },
  },
};

</script>
